import React, { useRef } from 'react';
import style from './availabilityCard.module.css';
import CloseIcon from "../../assets/images/close-cross.png";
import urgentCareAccepted from "../../assets/images/urgent-care-accepted.png";
import phoneIcon from "../../assets/images/mobileIcon.svg";

function AvailabilityCard({ center,
  memoizedAppointments,
  topLocationsStatus,
  hasTopLocations,
  hasError,
  onClick = () =>
    console.warn("Appointment Selector does not have an onClick bound"),
  selectedLocation,
  selectedAppointmentSlot,
  windowWidth,
  hasAppointments,
  appointmentType,
  searchAddress }) {
  const options = useRef([]);

  if (topLocationsStatus === "idle" && !hasTopLocations && searchAddress)
    return (
      <div className="bg-white rounded-lg my-4 p-4  media" style={{ display: "flex", justifyContent: "center", width: windowWidth < 768 ? "93%" : "100%", padding: "35px 0px 35px 0px", marginBottom: "0px", marginLeft: "0rem", alignItems: "baseline" }}>
        <div className="media_left media_item" style={{ marginLeft:"15px" }}>
          <img src={CloseIcon} alt='closeIcon' />
        </div>
        <div className="media_right media_item" style={{ marginRight: windowWidth < 1024 ? "18px" : "10px" }}>
          <p>
            Sorry, we do not have any centers within 50 miles of your location.
            Please try searching another location or{" "}
            <a href="mailto:info@heartandpaw.com">email</a> us if you have any
            questions.
          </p>
        </div>
      </div>
    );

  else if (!hasAppointments && appointmentType === "care" && searchAddress && topLocationsStatus === "idle") {

    return (
      <div className="bg-white rounded-lg my-4 p-4  media" style={{ display: "flex", justifyContent: "center", width: "96%", padding: "35px 0px 35px 0px", alignItems: "baseline" }}>
        <div className="media_left media_item">
          <img src={CloseIcon}  alt='closeIcon'/>
        </div>
        <div className="media_right media_item">
          <p>We could not find any available appointments that matched your search criteria. Try changing your search location.
          </p>
        </div>
      </div>
    )
  }

  else if (!hasAppointments && !hasTopLocations && appointmentType === "care" && searchAddress && topLocationsStatus === "error") {

    return (
      <div className="bg-white rounded-lg my-4 p-4  media" style={{ display: "flex", justifyContent: "center", width: "96%", padding: "35px 0px 35px 0px", alignItems: "baseline" }}>
        <div className="media_left media_item">
          <img src={CloseIcon} alt='closeIcon' />
        </div>
        <div className="media_right media_item">
          <p>We could not find any available appointments that matched your search criteria. Try changing your search location.
          </p>
        </div>
      </div>
    )
  }

  return (
    <>
      {memoizedAppointments.map((location) => (


        <div
          key={location.id}
          className={`${style.cardParent} bg-white rounded-xl p-4 w-full md:w-1/3  mr-3 mb-3 flex flex-col`}
        >
          <img
            src={location?.img?.link || 'https://heartandpaw.com/wp-content/uploads/2024/03/animal-care-center-exterior.jpg'}
            alt={location?.displayName}
            className={`${style.imgFix} w-full h-32 object-cover rounded-xl`}
          />
          <div className="mt-4">
            <div className={`${style.headingParent}`}>
              <h3 className={`${style.centerName} text-lg font-semibold`}>{location?.displayName}</h3>
              <p className="text-gray-600 text-right" style={{ lineHeight: "25px",fontWeight:'bold',color:"black" }}>
                {location?.wp?.distanceText}
              </p>
            </div>
            <p className={`${style.centerAddress} text-gray-600`}>
              {`${location?.wp?.meta?.locations_meta_address?.street}${location?.wp?.meta?.locations_meta_address?.street2 ? " " + location?.wp?.meta?.locations_meta_address?.street2 : ""
                } ${location?.wp?.meta?.locations_meta_address?.city}, ${location?.wp?.meta?.locations_meta_address?.state} ${location?.wp?.meta?.locations_meta_address?.zip}`}
            </p>
             
            <div className={`${style.urgentCare}`}>
              <div className={`${style.urgentCareParent}`}>  
              <a href={`tel:${location?.wp?.meta?.locations_meta_phone}`} className={`${style.phoneNumber}`}>
              <img src={phoneIcon} alt='Phone' className={`${style.phoneIcon}`} /> {location?.wp?.meta?.locations_meta_phone}
              </a>
                
              {location?.isUrgentVetCare && 
              <div className={`${style.urgentCareaccepted}`}><img src={urgentCareAccepted} className={`${style.urgentCareacceptedImage}`} alt='UrgentCareAccepted'/></div>
              }
            </div>
            </div>
              
            <div className={`${ (Object.keys(location.availableSlots).some(date => location.availableSlots[date].slots.length > 0) && Object.keys(location.availableSlots).length > 0)? style.slotsFix: style.noFix}`}>
              {Object.keys(location.availableSlots).length > 0 ?
                (<div className={`${style.timeSlots} mt-2 grid grid-cols-3`}>
                  {Object.keys(location.availableSlots).map((date) => {
                    const slots = location.availableSlots[date].slots;

                    return slots.length > 0 ? (
                      slots.map((slot) => (
                        
                          <button
                            key={slot.key || selectedAppointmentSlot?.key}
                            ref={(el) => options.current.push(el)}
                            role="radio"
                            aria-checked={selectedAppointmentSlot?.key === slot.key}
                            onClick={() => {
                              let apptData = { ...slot };
                              onClick({ ...apptData }, { ...location.wp, timezone: location.timezone });

                            }}
                            className={`${style.timeCount} text-center py-2 px-4 rounded-md focus:outline-none  ${selectedAppointmentSlot?.key === slot.key
                                ? style.customSelect
                                : "bg-gray-200 border-gray-200"
                              }`}
                          >
                            {slot.regularTime}
                          </button>
                        
                      ))
                    ) : (
                      
                      
                        <p key={date} className="text-grey-500 col-span-3 text-center">
                          <b>
                          No appointments today.
                          </b>
                        </p>
                        
                      
                    );
                  })}
                </div>) : (
                  <div className={`${style.timeSlots} mt-2 col-span-3 text-center`}><p><b>No appointments today.</b></p></div>
                )}
            </div>
          </div>
        </div>

      ))}
    </>
  );
}

export default AvailabilityCard;